import * as fi from 'date-fns/locale/fi';
import { format } from 'date-fns';
import * as React from 'react';
import styled from 'react-emotion';
import ReactConfetti from 'react-dom-confetti';
import * as ReactMarkdown from 'react-markdown';
import { DiscussionEmbed } from 'disqus-react';
import Layout from '../components/Layout';
import meImage from '../images/me.jpg';
import { Button } from '../components/Button';
import background from '../images/background.svg';
import background2 from '../images/background2.svg';
import { colors } from '../theme';
import { Footer } from '../components/Footer';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-diff';
import 'prismjs/themes/prism-tomorrow.css';
import { IPost } from './post';
import { SEO } from '../components/SEO';

const BlogLayout = styled(Layout)`
  height: 100vh;
  perspective: 2px;
  overflow: auto;
  position: relative;
`;

const Confetti = styled(ReactConfetti)`
  position: fixed !important;
  bottom: 0;
  left: 50%;
`;
const Comments = styled.div`
  margin-top: 2em;
`;

const Background = styled.div`
  position: relative;
  overflow: hidden;
  &:after,
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Move the pseudo-element back away from the camera,
 * then scale it back up to fill the viewport.
 * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
    transform: translateZ(-0.2px) scale(1.2);
    /* Force the background image to fill the whole element. */
    background-size: 100%;
    /* Keep the image from overlapping sibling elements. */
    z-index: -1;
    background: url(${background});
    background-position: 60% 25px;
  }
  &:before {
    content: '';
    transform: translateZ(-0.3px) scale(1.2);
    background: url(${background2});
  }
`;

const FeedbackTitle = styled.strong`
  display: block;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  margin-bottom: 1em;
  color: ${colors.copy};
  padding: 3rem;
  padding-top: 1em;
  padding-bottom: 1rem;
  font-size: 18px;
  @media (max-width: 800px) {
    padding: 1rem;
    padding-top: 0;
  }
  strong {
    font-weight: 600;
  }
  a {
    color: ${colors.link};
    text-decoration: none;
  }
  strong,
  h1,
  h2,
  h3 {
    color: ${colors.heading};
    a {
      color: inherit;
      text-decoration: underline;
    }
  }
  hr {
    margin: 2em 0;
    border: 0;
    border-top: 4px solid #e8e8e8;
  }
`;

const Markdown = styled(ReactMarkdown)`
  video {
    max-width: 500px;
    display: block;
    margin-top: 1rem;
    margin-left: 1rem;
    float: right;
    position: relative;
    z-index: 10;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol li {
    margin-bottom: 1rem;
  }
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.5rem;
  }
  h2,
  h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  h1 + h2,
  h1 + table + h2 {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    color: rgba(51, 51, 51, 0.49);
    margin-top: 0;
  }

  p {
    line-height: 1.7em;
  }

  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.5em;
  }
  blockquote p {
    margin-top: 2em;
    margin-bottom: 2em;
    border-left: 2px solid #e8e8e8;
    padding-left: 1em;
  }
  p code {
    background: #f2f3f4;
    border: 1px solid #e8e8e8;
    padding: 3px;
    border-radius: 3px;
    font-size: 16px;
  }
  table {
    float: right;
    margin-left: 3em;
    max-width: 100%;
    margin-bottom: 1em;
    margin-top: 1em;
    font-weight: 700;
    text-transform: uppercase;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
    letter-spacing: 0.06em;
    text-align: center;
    @media (max-width: 800px) {
      margin-left: 0;
      width: 100%;
      float: none;
    }
  }
  img {
    max-width: 100%;
  }
  img[style*='float'],
  iframe[style*='float'],
  svg[style*='float'] {
    margin-top: 1em;
    margin-left: 1em;
    margin-bottom: 1em;
  }
  @media (max-width: 800px) {
    img,
    svg,
    iframe {
      float: none !important;
      display: block;
    }
  }
  table img {
    margin-bottom: 0;
  }

  th:not(:first-child),
  td:not(:first-child) {
    padding-left: 1em;
  }
  th:first-child,
  td:first-child {
    padding-right: 1em;
  }

  form {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media (max-width: 800px) {
      margin-bottom: 2rem;
      flex-direction: column;
      button {
        margin-top: 1rem;
      }
    }
  }

  input {
    border: 0;
    height: 67px;
    background: #f2f2f2;
    border-radius: 9px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 162%;

    display: flex;
    align-items: center;
    text-indent: 22px;
    width: 359px;
    color: #757575;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  button {
    border: 0;
    text-align: center;
    height: 67px;
    width: 186px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 162%;
    margin-left: 1rem;
    color: #ffffff;
    background: #4181f1;
    border-radius: 9px;
    @media (max-width: 800px) {
      margin-left: 0;
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    ul {
      padding: 0;
    }
    li:before {
      content: '-';
    }
  }
`;
const Image = styled.img`
  width: 60px;
  margin-right: 1em;
  float: left;
  border-radius: 3px;
`;

const Author = styled.div`
  margin-top: 2em;
`;
const ClosingWords = styled.h2`
  margin-top: 3rem;
  margin-bottom: 1rem;
`;

const PublishedOn = styled.span`
  font-size: 16px;
  color: ${colors.copy};
`;

const Logo = styled.h1`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 107.4%;
  letter-spacing: -0.025em;
  text-transform: uppercase;
  color: #3f3f3f;
  margin: 0;
`;

const FeedbackButtonsWrapper = styled.div`
  position: relative;
`;

const FeedbackButtons = styled.div``;

const Feedback = styled.div<{ visible: boolean }>`
  max-height: 300px;
  transition: max-height 600ms;
  overflow: hidden;
  ${({ visible }) => (visible ? '' : 'max-height: 0')};
`;

const ProblemButton = styled(Button.withComponent('a'))`
  color: #fff !important;
`;

class BlogPostTemplate extends React.Component<{ pageContext: IPost }> {
  public state = {
    feedbackSent: false
  };
  public componentDidMount() {
    Prism.highlightAll();
  }

  public componentDidUpdate() {
    Prism.highlightAll();
  }

  private giveFeedback(answer: string) {
    return () => {
      this.setState(() => ({ feedbackSent: true }));
    };
  }

  public render() {
    const post = this.props.pageContext;

    const { slug, title, createdAt, language, feedback, comments } = post;

    const disqusConfig = {
      shortname: 'okaycodes',
      config: { identifier: slug, title, url: `/${slug}` }
    };

    const publishedThisYear =
      new Date(createdAt).getFullYear() === new Date().getFullYear();

    const timestamp = publishedThisYear
      ? language === 'fi'
        ? format(createdAt, 'D. MMMM[ta] YYYY', { locale: fi })
        : format(createdAt, 'MMM D YYYY')
      : format(
          createdAt,
          'MMM Do YYYY',
          language === 'fi' ? { locale: fi } : {}
        );

    return (
      <div>
        {' '}
        <Confetti
          active={this.state.feedbackSent}
          config={{
            spread: 60,
            startVelocity: 40,
            elementCount: 100,
            decay: 0.95
          }}
        />
        <BlogLayout logo={<Logo>Okay codes</Logo>}>
          <Background>
            <SEO
              title={`${post.title} - Okay codes`}
              description={post.preview}
            />
            <Container>
              {language === 'fi' ? (
                <PublishedOn>Julkaistu {timestamp}</PublishedOn>
              ) : (
                <PublishedOn>Published on {timestamp}</PublishedOn>
              )}
              <Markdown escapeHtml={false} source={post.content} />
              <ClosingWords>I 💜 feedback!</ClosingWords>
              {language === 'fi' ? (
                <p>
                  Kiitos ajastasi! Mikäli joku jäi vielä vaivaamaan mieltä tai
                  haluat kuulla lisää, vastaan mielellään kysymyksiin joko
                  sähköpostilla <strong>riku@okaycodes.fi</strong> tai
                  Twitterissä{' '}
                  <a href="https://twitter.com/rikurouvila">@rikurouvila</a>.
                </p>
              ) : (
                <p>
                  First of all: <strong>thank you so much for reading!</strong>
                  <br /> If you have something to ask, don't agree with
                  something I say or just wanna have a chat, feel free to ping
                  me on <a href="https://twitter.com/rikurouvila">Twitter</a>.
                </p>
              )}
              {feedback && (
                <Feedback visible={!this.state.feedbackSent}>
                  <FeedbackTitle>Saitko automaation toimimaan?</FeedbackTitle>
                  <FeedbackButtonsWrapper>
                    <FeedbackButtons>
                      <Button onClick={this.giveFeedback('works')}>
                        👍 Sain toimimaan
                      </Button>{' '}
                      <ProblemButton href="https://docs.google.com/forms/d/e/1FAIpQLSeKshSo2eA4YuEaFX22izCdJ8LPDK_MNlqMkPHNg8H_E_HIhw/viewform?usp=sf_link">
                        😔 En saanut toimimaan
                      </ProblemButton>
                    </FeedbackButtons>
                  </FeedbackButtonsWrapper>
                </Feedback>
              )}
              <Author>
                <Image
                  alt="Freelance Software Developer in Helsinki, Finland"
                  src={meImage}
                />
                <strong>Riku Rouvila</strong>
                <br />
                {language === 'fi' ? (
                  <span>
                    Ohjelmistokehittäjä, kouluttaja ja{' '}
                    <a href="https://webbidevaus.fi/">webbidevaus.fi</a>{' '}
                    podcastin juontaja 🎙
                  </span>
                ) : (
                  'Software developer'
                )}
              </Author>
              <Comments>
                {comments && <DiscussionEmbed {...disqusConfig} />}
              </Comments>
            </Container>
            <Footer />
          </Background>
        </BlogLayout>
      </div>
    );
  }
}

export default BlogPostTemplate;
