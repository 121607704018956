export const colors = {
  copy: '#626262',
  heading: '#3f3f3f',
  link: '#009dff'
};

export const sizes = {
  copy: '18px',
  title: '22px'
};

export const layout = {
  fullWidth: '1024px'
};
