import * as React from 'react';
import styled from 'react-emotion';

const Container = styled.footer`
  padding: 0 1rem;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  font-family: Inconsolata;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 169.69%;
  text-align: center;
  letter-spacing: 0.06em;
  color: #626262;
`;

export const Footer = () => (
  <Container>
    Okay codes Oy 2949996-8
    <br />
    00100 Helsinki
  </Container>
);
