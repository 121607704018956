import * as React from 'react';
import { graphql } from 'gatsby';
import BlogPostTemplate from './template';

export default function Template({ data }: any) {
  return (
    <BlogPostTemplate
      pageContext={{
        // PATH here awkwardly converted to slug
        slug: data.markdownRemark.frontmatter.path.replace('/', ''),
        title: data.markdownRemark.frontmatter.title,
        comments: data.markdownRemark.frontmatter.comments,
        feedback: data.markdownRemark.frontmatter.feedback,
        preview: data.markdownRemark.frontmatter.preview,
        createdAt: new Date(data.markdownRemark.frontmatter.createdAt),
        language: data.markdownRemark.frontmatter.language,
        content: data.markdownRemark.rawMarkdownBody
      }}
    />
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      rawMarkdownBody
      frontmatter {
        createdAt
        path
        title
        preview
        language
        comments
        feedback
      }
    }
  }
`;
