import styled from 'react-emotion';

export const Button = styled.button`
  border: 0;
  text-align: center;
  padding: 1rem 3rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #ffffff;
  cursor: pointer;
  background: #4181f1;
  border-radius: 9px;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  @media (max-width: 800px) {
    margin-left: 0;
    width: 100%;
  }
`;
