import { graphql, StaticQuery } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';
import ogImage from '../images/ogimage.png';
import twitterImage from '../images/twitterimage.png';

const URL = 'https://okaycodes.fi';
const DESCRIPTION =
  'Okay codes on moderniin applikaatiokehitykseen erikoistunut ohjelmistoyritys Helsingissä. Tarjoamme konsultointia, prototyyppejä ja tukea yrityksen oman ohjelmistokehityskyvykkyyden kehittämiseen.';

export function SEO({
  title,
  description
}: {
  title?: string;
  description?: string;
}) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={title || data.site.siteMetadata.title}
            htmlAttributes={{ lang: 'fi' }}
            meta={[
              {
                name: 'title',
                content: title || data.site.siteMetadata.title
              },
              {
                name: 'description',
                content: description || DESCRIPTION
              },
              {
                name: 'keywords',
                content:
                  'ohjelmistokehittäjä,helsinki,tampere,no code,zapier,thunkable,devaaja,koodari,web development,freelancer,react,full stack,training,microservices,architecture,developer,contentful'
              },
              {
                property: 'og:title',
                content: title || data.site.siteMetadata.title
              },
              {
                property: 'og:url',
                content: URL
              },
              {
                property: 'og:description',
                content: description || DESCRIPTION
              },
              {
                property: 'og:image',
                itemprop: 'image',
                content: `https://okaycodes.fi${ogImage}`
              },
              // Twitter
              {
                property: 'twitter:image',
                content: `https://okaycodes.fi${twitterImage}`
              },
              { property: 'twitter:card', content: 'summary_large_image' },
              { property: 'twitter:url', content: URL },
              {
                property: 'twitter:title',
                content: title || data.site.siteMetadata.title
              },
              {
                property: 'twitter:description',
                content: description || DESCRIPTION
              }
            ]}
          />
        </>
      )}
    />
  );
}
